.home-feature {
  // background: linear-gradient(rgba(6, 133, 199, 0.1), rgb(255, 255, 255));
}

.home-container {
  max-width: 1200px;
}

.home-banner {
  width: 100%;
  height: 400px;
  //text-align: center;
  background-position: center center;
  img {
    border-radius: 10px;
    height: 100%;
    width: 100%;
  }
}

.bg-cover-full {
  background-size: cover !important;
  background-repeat: no-repeat;
}

.home-category {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.small-category {
  height: 150px;
  width: 100%;
  background-color: #efd28d;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 100px;
    width: 100px;
  }
}

.home-full {
  min-height: calc(100vh - 60px);
}
