.cart-header{
    box-shadow: 0 4px 20px 0 rgba(0,0,0,0.06);
    .logo{
        max-width: 120px;
        img{
            height: 100%;
            width: 100%;
        }
    }
}
.cart-nav{
    *{
        font-size: 14px;
    }
    span{
        text-transform: uppercase;
        font-weight: bold;
    }
    svg{
        margin-right: 25px;
        font-size: 25px;
    }
}

.cart-coupon{
    .apply{
        border: 1px solid rgba(0, 0, 0, .9);
        border-radius: 16px;

    }
}
.summary-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h3{
        font-weight: 800;
        margin: 15px 0;
        font-size: 16px;
        color: #000;
    }
    p{
        font-weight: normal;
        letter-spacing: .3px;
        font-size: 14px;
        opacity: .9;
    }
    span{
        color: #6d6d6d;
        font-size: .875em;
    }
}

.cart-checkout{
    border-radius: 10px;
    padding: .8em 0;
    font-size: 14px;
}

.cart-item{
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    margin-bottom: 20px;
    background-color: #fff;
    display: flex;
    .img-wrapper{
        margin-right: 15px;
        img{
            max-width: 120px;
        }
    }
    .rate{
        font-weight: 800;
        font-size: 16px;
        color: #000;
    }
}

.payment-modes{
    img{
        max-width: 80px;
    }
}
.modal{
    background-color: rgba(0,0,0,.5);
}
.modal-title{
    p{
        color: #6d6d6d;
    }
}
.modal-content{
    border-radius: 8px !important;
}
.form-check-input{
    height: .875em;
    box-shadow: none !important;
    &:checked{
        background-color: #02b875;
        border-color: #02b875;
    }
}

.payment-frame{
    height: 100vh;
    width: 100%;
}

.cart-trackbar{
    .progress-track{
  ul{
        margin-top: auto !important;
        display: flex;
        li{
            display: flex;
            align-items: center;
        }
        .circle{
            height: 20px !important;
            width: 20px !important;
            line-height: 20px;
            font-size: 12px;
        }
        .line{
            width: 100px !important;
            margin-left: 0px !important;
        }
        .content{
            top: 27px !important;
            left: 50% !important;
            p{
                font-weight: 600;
            font-size: 11px !important;
            }
            transform: translateX(-50%) !important;
        }
    }
    }
  
}

.payment-page{
    min-height: 100vh;
    padding-top: 10vh;
}