.account-sidebar {
  .profile {
    img {
      height: 70px;
      width: 70px;
      border-radius: 50%;
    }
  }

  .menu-item {
    display: flex;
    align-items: center;
    
    
  }
}

.profile-view{
  padding-top: 200px;
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  
  background-image: url('https://img.freepik.com/free-vector/people-face-masks-with-baskets-supermarket_107791-9817.jpg?t=st=1650936071~exp=1650936671~hmac=2c46adcf60a1d5d6c6a8861d1226bad44413fb1d9d79309c6a9eeb831c4a3a22&w=1800');
}

.profile-pic-view{
  margin-top: -50px;
  // border-radius: 50%;
  // overflow: hidden;
}

.chat-frame{
  width: 100%;
  height: 70vh;
}

.service-category-item{
  // height: 150px;
  background-color: #f8f8f8;
}


.service-category-banner{
  height: 25vh;
  width: 100%;
  img{
    z-index: -2;
  }
  input{
    z-index: 99;
  }

}

.service-category-list{
  height: 75vh;
  position: relative;
  background-color: white;
  
}
.fade-up-category{
  z-index: 0;
  top: -100px;
  height: 100px;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(180deg,transparent,rgba(255,255,255,.8),white);
}