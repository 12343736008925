


.track{
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    background-color: #f7f7f7;
}
.track-container{
    max-width: 800px;
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;

}