.auth-layout {
  height: 100vh;
  width: 100vw;
  background: #f2e2ba;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    padding: 40px;
    background-color: #fff;
    border-radius: 32px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.02);
    width: 500px;
    max-width: 500px;
    .border-light {
      border-color: #f2f2f2 !important;
    }
  }
  .close-auth {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .form-floating {
    display: flex;
    align-items: center;
    color: #444;

    textarea,
    input {
      height: 40px !important;
      padding: 7px 10px !important;
      box-shadow: none !important;
      font-size: 13px;

      &:focus {
        border-color: #111;
      }
    }

    label {
      top: 50%;
      transform: translateY(-50%);
      height: 11.5px;
      line-height: 11.5px;
      padding: 0 5px;
      margin: auto 10px;
      font-size: 12px;
      opacity: 1 !important;
      transition: all 300ms;
    }

    label.active {
      top: 0;
      transform: scale(0.95) translateY(-0.5rem) translateX(0.15rem);
      background: #fff;
    }
  }
}

.info-layout {
  .box {
    max-width: 800px;
    width: 800px;
  }
}
