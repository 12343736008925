
.breadcrumb{
    padding: 20px 0px;
    *{
        font-size: 13px;
    }
    a[href]{
        letter-spacing: .5px;
        text-transform: capitalize;
        opacity: 1;
        color: #333;
    }
    a{
        opacity: .5;
    }
    svg{
        font-size: 16px;
        margin-right: 7px;
        vertical-align: top;
    }
    .breadcrumb-item{
        &::before{
            display: none;
        }
    }
}

.image-gallery{
    position: relative;
    .product-label{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99;
        p{
            opacity: .6;
            word-spacing: 3px;
            text-transform: uppercase;
            font-size: 10px;
            border-radius: 3px;
            padding: 8px 15px;
            color: white;
            backdrop-filter: blur(8px);
        }
    }

    ul{
        margin-top: 55px;
        list-style: none;
        li{

            div{
            img{
                border-radius: 4px;
                height: 40px;
            }
        }
        
        padding: 3px;
        margin-bottom: 10px;
        border-radius: 4px;
        border:1px solid transparent;
        transition: border 300ms linear;
        }
        .active{
            border-color:#4a4a4a;
        }
    }
}
h1,h2,h3,h4,h5,h6{
    color: #4a4a4a;
    line-height: 1.5;
}

.product-detail{
    .brand-name{
        a{
            color: #8e8e8e;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: 500;
            font-size: 16px;
        }
        margin-bottom: 10px;
    }
    h1{
        font-size: 1.25em !important;
        color: #4a4a4a;
        font-weight: 700;
    }

    .price{
        *{
            vertical-align: baseline;
        }
        h2{
            font-size: 1.35em;
            font-weight: 800;
            color: #000;
            margin-right: 12px;
            margin-bottom: 0;
        }
        span{
            color: #b2b2b2;
            font-size: 11px;
        }
    }
    .share{
        height: 37px;
        width: 37px;
        font-size: 20px;
        //border:1px solid rgba(1,1,1,.3)
    }
}

.product-highligths{
    padding: 16px;
    .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            height: 75px;
            width: 75px;
            padding: 15px;
            background-color: #f8f8f8;
            border-radius: 50%;
        }
        h3{
            font-size: 13px;
            color: #212121;
            padding-top: 8.5px;
            margin-bottom: 0;

        }
        p{
            color: #666675;
            font-size: 11px;
            margin-bottom: 0;
        }
    }
}

.product-action{
    *{
        letter-spacing: 1px;
        font-size: 14px;
    }
    padding: 16px 0;
    .btn,div[role='button']{
        border:1px solid rgba(0,0,0,.1) !important;
        border-radius: 16px !important;
        min-height: 40px;
    }
    .cart-btn{
        padding: 16px 0;
    }

}

.availability{
    font-size: 12px;
    form{
        border-radius: 8px;
    }
    input{
        &::placeholder{
            font-size: 12px;
        }
    }
    .status{
        margin:10px;
        height: 20px;
        width: 25px;
        text-align: center;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            color: #fff;
        }
    }
}

.price-option{
    border: 1px solid #e9e9e9;
    border-radius: 16px;
    padding: 10px 16px;
    transition: border 300 ms linear;
    color: #000;

}

.price-option.active{
    border-color: rgba(0,0,0,.9);
}