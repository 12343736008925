.header.stick {
  position: fixed;
  width: 100%;
  left: 0;
  background-color: #fff;
  top: 0;

  box-shadow: 1px 14px 20px 1px rgba(153, 153, 153, 0.2);
}
.header {
  position: relative;
  z-index: 100;
    border-bottom: 1px solid #f7f7f7;
  .logo {
    width: 110px;
    img {
      width: 100%;
    }
  }
  nav {
    min-height: 67px;
  }
}
.location {
  max-width: 260px;
  .dropdown-menu{
      padding: 0px !important;
      .feature{
          margin-bottom: 0 !important;
      }
  }
}

.desktop-menu {
  li {
    a {
      // padding: 25px 20px 17px;
      font-size: 14.5px;
      color: #111;
      svg {
        color: #999;
        margin-right: 6px;
      }
      span {
        vertical-align: text-top;
      }
    }
  }
}

.search-box {
  background-color: #f5f5f5;
  border-radius: 7px;
  overflow: hidden;
}

.custom-dropdown {
  .dropdown-toggle {
    &::after {
      display: none;
    }
    position: relative;
  }
  .dropdown-menu {
    border: 0;
    overflow: hidden;
    padding: 0;
    color: #585858;
    border-radius: 10px;
    box-shadow: 1px 6px 24px 0 rgba(0, 0, 0, 0.1);
    min-width: 100%;
    margin-top: 0;
    .dropdown-item {
      color: inherit !important;
      font-size: 14px;
      background-color: inherit !important;
    }
  }
}

.nav-user {
  .dropdown-toggle {
    z-index: 10001;
  }
  .dropdown-menu {
    min-width: 300px;
    margin-top: -20px;
    margin-right: -50px;
    box-shadow: 0 -6px 24px 0 rgba(0, 0, 0, 0.5);
  }
  
}

.nav-user{
  &:hover{
    .menu{
      display: block;
    }
  }
  .menu{
    z-index: 999;
    position: absolute;
    display: none;
    position: absolute;
    width: 300px;
    right: auto;
    border-radius: 7px;
    overflow: hidden;
    // margin-top: 20px;
    margin-left: -150px;
    background-color: white;
    box-shadow: 0 -6px 24px 0 rgba(0, 0, 0, 0.5);

  }
  .menu-items {
    li {
      padding: 6px 8px 9px;
      margin-top: 10px;
      border-bottom: 1px solid #f2f2f2;
  
      a {
        display: flex;
  
        span {
          flex-grow: 1;
        }
  
        color: #454545;
        font-size: 15px;
  
        svg {
          margin: 0px 20px;
        }
      }
    }
  }
}

.fw-600 {
  font-weight: 600;
}

.nav-category{
  .dropdown-menu{
    width: 100vw;
    left: 0;
    box-shadow: 1px 20px 24px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }
}

footer {
  a {
    color: #4a4a4a;
    font-weight: 600;
  }
}

@media (max-width: 576px) {
  .desktop-menu {
    display: none !important;
  }

  #nav{
    padding-top: 10px;
  }

  #cart-col{
    order: 0;
    margin-left: auto;
    margin-right: 4px;
  }

  #search-col{
    order: 1;
    padding: 8px 0;
  }
}


