.search-sidebar {
  width: 250px;
  .search-filter {
    ul {
      transition: all 300ms;
      overflow-y: auto;
      max-height: 0px;
    }
    .expended {
      max-height: 1000px;
    }
  }
  .filter-child {
    transition: all 300ms linear;
    max-height: 400px;
    ul {
      padding-top: 8px;
      li {
        margin-bottom: 1rem;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        font-size: 14px;
        p {
          margin-bottom: 0;
          color: #000;
          letter-spacing: 0.2px;
        }
      }
    }
    .form-check {
      .form-check-input {
        border-radius: 0 !important;
        margin-right: 1rem;
      }
    }
  }
}

.single-product-view{
  width: 19%;
}

.single-product {
  .wishlist-button{
    position: absolute;
    right: 6px;
    top: 8px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  .img-wrapper {
position: relative;
    border-radius: 8px;
    overflow: hidden;
    // padding: 10px;
    img {
      transition: transform 300ms linear;
      &:hover{
        transform: scale(1.05);
      }
      max-width: 100%;
    }
  }
  h2 {
    font-size: 0.875rem;
    color: #000;
  }
  .title {
    text-transform: capitalize;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 10px;
    color: #939393;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .price {
    p {
      font-size: 16px;
      color: #19132f;
      font-weight: 900;
      margin-bottom: 0;
    }
  }
}


@media (max-width:576px){

  .single-product-view{
    width: 50% !important;
  }
}